<template>
	<section class="main-content benefits">
		<div class="row">
			<div class="columns column4">
				<h2 class="subtitle">{{ subtitle }}</h2>
				<h2>{{ title }}</h2>
				<ul>
					<li v-for="(benefit, key) in benefits" :key="key">
						<font-awesome-icon icon="fa-light fa-check" size="1x" />
						<span>{{ benefit }}</span>
					</li>
				</ul>
				<a
					class="button button-cta reserve-now"
					target="_blank"
					:href="`https://booking.eu.guestline.app/DELPHI/availability?hotel=DELPHI&lng=${locale}`"
					rel="noopener noreferrer"
				>
					{{ bookButton }}
				</a>
			</div>
		</div>
		<picture>
			<source v-if="image.imageWebp" :srcset="image.imageWebp" type="image/webp" />
			<source :srcset="image" />
			<img class="fade-image" :src="image.image" :alt="image.imageAlt" loading="lazy" />
		</picture>
	</section>
</template>

<script setup>
const { locale } = useI18n();

defineProps({
	title: { type: String, default: '' },
	subtitle: { type: String, default: '' },
	bookButton: { type: String, default: '' },
	benefits: { type: Array, default: () => [] },
	image: {
		type: Object,
		default: () => ({
			image: '',
			imageAlt: '',
			imageWebp: '',
		}),
	},
});
</script>

<style lang="scss" scoped>
.benefits .fade-image {
	opacity: 1;
}

.benefits {
	position: relative;
	padding: 75px 0;

	.row {
		z-index: 2;
		position: relative;
	}

	.column4 {
		padding: 60px 0 60px 1em;
	}

	ul {
		list-style: none;
		font-size: 20px;
		margin: 30px 0;
		padding-left: 0;
	}

	li {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		margin: 20px 0;

		span {
			width: calc(100% - 70px);
		}

		svg {
			content: '';
			font-size: 26px;
			color: #fff;
			display: block;
			margin: 0 20px 0 0;
		}
	}
}

@media (max-width: 1180px) {
	.benefits .column4 {
		width: 50%;
	}
}

@media (max-width: 920px) {
	.benefits .column4 {
		width: 100%;
	}
}

@media (max-width: 680px) {
	.benefits {
		display: flex;
		flex-flow: column-reverse wrap;
		padding: 0;

		.row {
			width: 100%;
		}

		.column4 {
			background: #335438;
			padding: 40px calc(1em + 2%);
		}

		img {
			position: relative;
		}
	}
}
</style>
