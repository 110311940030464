<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageAlt: page.headingImageAlt,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" ref="heroContent" class="hero-content">
					<div class="hero-logo" />
					<div class="title-div hero-title">
						<span v-for="(letter, index) in defaults[locale].homepage.headingTitle" :key="index">
							{{ letter }}
						</span>
					</div>
					<div class="title-div hero-subtitle">
						<div>
							{{ defaults[locale].homepage.headingSubTitle }}
						</div>
					</div>
				</div>
			</template>
		</heading-image>

		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:introduction-content="page.introductionContent"
		/>

		<booking-benefits
			:title="defaults[locale].homepage.sectionBenefitsTitle"
			:subtitle="defaults[locale].homepage.sectionBenefitsSubtitle"
			:benefits="defaults[locale].homepage.bookingBenefits"
			:book-button="defaults[locale].website.book"
			:image="{
				image: defaults[locale].homepage.sectionBenefitsImage,
				imageAlt: defaults[locale].homepage.sectionBenefitsImageAlt,
				imageWebp: defaults[locale].homepage.sectionBenefitsImageWebp,
			}"
		/>

		<rooms-promotions
			:items="roomsData"
			:header="defaults[locale].rooms.sectionRoomsTitle"
			:subtitle="defaults[locale].rooms.subtitle"
			:content="defaults[locale].rooms.sectionRoomsContent"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:contentblocks="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<promoblocks
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:promoblocks="promoblocksData"
			:amount="4"
		/>

		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
			:image="{
				image: defaults[locale].newsletter.newsletterSectionImage,
				imageAlt: defaults[locale].newsletter.newsletterSectionImageAlt,
				imageWebp: defaults[locale].newsletter.newsletterSectionImageWebp,
			}"
		/>

		<news-overview :news="news" />

		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer
			v-if="page && page.footerSection && page.footerSection.length"
			:footer-section="page.footerSection[0]"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { language: locale.value },
	key: `${locale.value}/rooms`,
});

const heroContent = ref(null);
const staggerDelay = 60;

onMounted(() => {
	if (heroContent.value) {
		const letters = heroContent.value.querySelectorAll('span');
		letters.forEach((letter, index) => {
			letter.style.opacity = 0;
			setTimeout(() => {
				letter.style.opacity = 1;
			}, index * staggerDelay);
		});
	}
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	bottom: 28%;
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	padding: 0 20px;
	color: #fff;

	span {
		transition: opacity 0.5s ease-in-out;
		opacity: 0;
	}
}

.hero-logo {
	width: 340px;
	height: 181px;
	background: url('~/assets/images/logo-the-delphi-horizontal-full-white.png') no-repeat center center;
	background-size: 340px;
	margin: 0 auto 50px;
	animation: fade-in 1.8s ease forwards;
	animation-delay: 2s;
	opacity: 0;
}

.hero-content .title-div {
	color: #fff;
	font-weight: 700;
	font-size: 42px;
	letter-spacing: 1px;
	width: 100%;
	font-family: var(--body-font-family);
	display: inline-block;
	margin: 0 0 10px;
	text-transform: uppercase;
}

.hero-content .hero-subtitle {
	font-size: 80px;
	font-weight: 100;
	font-family: var(--heading-font-family);
	text-transform: uppercase;
	opacity: 0;
	animation: fade-in 1.8s ease forwards;
	animation-delay: 2s;
}

@media (max-height: 800px) {
	.hero-content {
		bottom: 25%;

		.title-div {
			font-size: 36px;
		}

		.hero-subtitle {
			font-size: 68px;
		}
	}
}

@media (max-width: 1080px) {
	.hero-content {
		.hero-title {
			font-size: 36px;
		}

		.hero-subtitle {
			font-size: 62px;
		}
	}
}

@media (max-width: 880px) {
	.hero-content {
		top: 50%;
		bottom: auto;
		margin: -70px auto 0;

		.hero-title {
			font-size: 32px;
		}

		.hero-subtitle {
			font-size: 54px;
		}
	}

	.hero-logo {
		display: none;
	}
}

@media (max-width: 680px) {
	.hero-content {
		.hero-title {
			font-size: 30px;
		}

		.hero-subtitle {
			font-size: 44px;
		}
	}
}

@media (max-width: 520px) {
	.hero-content {
		.hero-title {
			font-size: 28px;
		}

		.hero-subtitle {
			font-size: 40px;
		}
	}
}
</style>
